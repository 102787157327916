$(document).ready(function () {

    // Breakpoint berechnen
    // Bitte anpassen an _einstellungen.scss
    // https://github.com/jerrylow/breakpoints
    $(window).breakpoints({
        breakpoints: [{
            'name': 'breakpointSmartphoneAb',
            'width': 0
        }, {
            'name': 'breakpointDesktopAb',
            'width': 800
        }]
    });

    // Body je nach Breakpoint anderes data-layout Attribut zuweisen
    var breakpoint;
    $(window).on('inside-breakpointSmartphoneAb', function () {
        $('body').attr('data-layout', 'smartphone');
        breakpoint = 'smartphone';
    });
    $(window).on('inside-breakpointDesktopAb', function () {
        $('body').attr('data-layout', 'desktop');
        breakpoint = 'desktop';
    });

    function menueToggle() {
        if (menueAktiv == true) {
            menueAktiv = false;
        } else {
            menueAktiv = true;
        }

        if (breakpoint == 'smartphone' || breakpoint == 'tabletHoch' || breakpoint == 'tabletQuer') {
            $.scrollLockMenue();
        }

        $('body').attr('data-menue-aktiv', menueAktiv);
    }

    // // Hamburgermenü aktivieren
    // $('#hamburger').click(function (event) {

    //     event.preventDefault();
    //     menueToggle();

    // });

    $('#menue').on('click', '.menue__link', function (event) {
        if (menueAktiv == true) {
            menueToggle();
        }
    });

    // Body Attribute scrolled = true geben, wenn gescrollt wurde
    var scrollposition = $(window).scrollTop();
    $(window).scroll(function (e) {
        var neueScrollposition = $(window).scrollTop();
        if (neueScrollposition - scrollposition > 50) {
            $('body').attr('data-scrolled', 'true');
        }
    });

    $('#scrollpfeil').on('click', function () {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    });
});